<template>
    <div class="surface-card p-6 border-1 surface-border">
        <div class="flex">
            <div class="text-2xl uppercase mb-4">Profil</div>
        </div>

        <Accordion :activeIndex="0">
            <AccordionTab header="Sicherheit & Passwort">
                <form class="py-3 pb-2" v-on:submit.prevent="submitProfile">
                    <div class="field grid" v-for="(label, index) in fields" :key="index">
                        <label class="col-fixed text-700 mt-1 w-4">
                            {{ label }}
                        </label>
                        <div class="col">
                            <InputText
                                class="w-full"
                                type="password"
                                required
                                v-model="accountSecurity[index]"
                            />
                            <small class="p-invalid block mt-2" v-for="message in accountSecurityErrors[index]"
                                   :key="message">
                                {{ message }}
                            </small>
                        </div>
                    </div>

                    <div class="surface-border border-top-1 opacity-50 mb-4 mt-4 py-0 col-12"></div>

                    <div class="text-center">
                        <Button type="submit" label="Speichern"></Button>
                    </div>
                </form>
            </AccordionTab>
            <AccordionTab :disabled="true">
                <template #header>
                    <i class="pi pi-lock mr-2"></i>
                    Erscheinung
                </template>
            </AccordionTab>
            <AccordionTab :disabled="true">
                <template #header>
                    <i class="pi pi-lock mr-2"></i>
                    Benachrichtigungen
                </template>
            </AccordionTab>
        </Accordion>
    </div>
</template>

<script>
    import InputText from "primevue/inputtext/InputText";
    import Accordion from "primevue/accordion";
    import AccordionTab from "primevue/accordiontab";
    import Button from "primevue/button";
    import util from '../../plugins/util';

    export default {
        components: {
            InputText,
            Accordion,
            AccordionTab,
            Button
        },
        methods: {
            submitProfile() {
                this.accountSecurityErrors = [];

                if (
                    this.accountSecurity['plainPassword'].length > 0
                    && this.accountSecurity['plainPassword'] !== this.accountSecurity['plainPasswordRepeat']
                ) {
                    this.accountSecurityErrors['plainPassword'] = ['Die Passwörter stimmen nicht überein'];
                    return;
                }

                this.$http.put('account/me', this.accountSecurity).then(response => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Erfolgreich',
                        detail: 'Passwort erfolgreich geändert. Sie können sich nun erneut einloggen.',
                        life: 3000
                    });
                    this.$router.push('/logout');
                }, response => {
                    this.accountSecurityErrors = util.mapConstraints(response.data);
                });
            }
        },
        data() {
            return {
                accountSecurity: {},
                accountSecurityErrors: {},
                fields: {
                    password: 'Altes Passwort',
                    plainPassword: 'Neues Passwort',
                    plainPasswordRepeat: 'Neues Passwort (Wied.)',
                }
            }
        }
    }
</script>
